import React from 'react';
import './style/Footer.css';
import './style/App.css';

import siteConfigs from './configs/config.json';

class SocialLink extends React.Component {
  render() {
    return (
      <div className='social_link' key = {this.key}>
        <a href={'link/' + this.props.info.name} rel="noopener noreferrer" target="_blank">
          <img src={require(`${this.props.info.logo}`)} alt={this.props.info.name} className='social_logo'/>
        </a>
      </div>
  )}
}


class Social extends React.Component {
  render() {
    var socialLinks = []
    this.props.social.map(function(dataRow, i){
      return (
        socialLinks.push(<SocialLink info={dataRow} key={i} />)
      )
    })
  return (
    <div className='social_block'>
      {socialLinks}
    </div>
  )
  }
}

class Footer extends React.Component {
  render() {
    return (
      <div className='footer_body'>
          <Social social={siteConfigs.footer_data} />
      </div>
      )}
}

export default Footer