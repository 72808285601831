import React from 'react';
import './style/App.css';


class Site extends React.Component {
  render() {
    return (
      <div className='page_body'>
        <div className='page_subHeading'>About this Site</div>
        <div className='page_section bottom_pad'>
          <p>This site is used primarily as a place for me to play around with React. More info about this site can be found in the <a href='link/readme' rel="noopener noreferrer" target="_blank">README.md</a></p>
          <p>Feel free to browse all the code that is used to create this site <a href='link/repo' rel="noopener noreferrer" target="_blank">here.</a></p>
        </div>
      </div>
      )}
}

export default Site