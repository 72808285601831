import React from 'react';
import './style/App.css';
import ReactGA from 'react-ga';
import ReactRedirect from 'react-redirect';
import siteConfigs from './configs/config.json';

ReactGA.initialize(siteConfigs.analytics);

function getRedirect(link_id) {
  var redirect = 'https://personal.rpdaley.com';
  if (link_id in siteConfigs.link_map) {
    redirect = siteConfigs.link_map[link_id];
    ReactGA.set({ page: link_id });
    ReactGA.pageview(link_id);
  }
  return redirect;
}

class RPLink extends React.Component {
  render() {
    var redirect_url = getRedirect(this.props.match.params.id);
    return (
      <ReactRedirect location={redirect_url || ''}>
        <div className='page_body'>
          <div className='page_subHeading'>Redirecting...</div>
          <div className='page_section bottom_pad'>
            <p>{'Redirecting to ' + redirect_url + '...'}</p>
            <p>You'll only see this if you have a slow Internet connection</p>
          </div>
        </div>
      </ReactRedirect>
    );
  }
}

export default RPLink