import React from 'react';
import './style/App.css';
import './style/Navigation.css';

import { NavLink } from 'react-router-dom';


class Navigation extends React.Component {
  render() {
    return (
      <div className='nav_bar'>
        <div className='nav_item'>
          <NavLink to="/" className="nav_link" activeClassName="nav_link_active" exact>
                Home
          </NavLink>
        </div>
        <div className='nav_item'>
          <NavLink to="/about" className="nav_link" activeClassName="nav_link_active" exact>
            About Me
          </NavLink>
        </div>
        <div className='nav_item'>
          <NavLink to="/site" className="nav_link" activeClassName="nav_link_active" exact>
            About This Site
          </NavLink>
        </div>
        <div className='nav_item'>
          <NavLink to="/contact" className="nav_link" activeClassName="nav_link_active" exact>
            Contact
          </NavLink>
        </div>
      </div>
  )}
}

export default Navigation