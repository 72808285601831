import React from 'react';

class Contact extends React.Component {
  render() {
    return (
      <div className='page_body'>
        <div className='page_subHeading'>Contact Me</div>
        <div className='page_section bottom_pad'>
          <p>The easiest way to contact me is through the social media links at the bottom of the page...</p>
          <p>...but if you really want to send me an email, I can be reached at <a href='mailto:rd@rpdaley.com'>rd@rpdaley.com</a></p>
        </div>
      </div>
      )}
}

export default Contact