import React from 'react';
import siteConfigs from './configs/config.json';
class Home extends React.Component {
  render() {
    const img_rpd = siteConfigs.root.rpd;
    return (
      <div className='page_body'>
        <div className='page_subHeading'>Home</div>
        <div className='page_section bottom_pad'>
          <img src={require(`${img_rpd}`)} alt="Ryan Daley" className='img_rpd'/>
          <p>Ryan Daley (RP Daley) is a Software Developer at Bell Media.</p>
          <p>He was formerly an Automation Engineer at Apple Inc., was an early employee at Kobo (Rakuten Kobo), and interned at Research In Motion (BlackBerry).</p>
          <p>He holds degrees from The University of British Columbia and The University of Guelph.</p>
          <p>When AFK, he enjoys travelling, playing guitar, and curating random collections of semi-related objects.</p>
          <p>He currently resides in Toronto, Canada.</p>
        </div>
      </div>
      )}
}

export default Home