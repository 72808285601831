import React from 'react';
import './style/App.css';
import './style/About.css';

import siteConfigs from './configs/config.json';

class AboutBlock extends React.Component {
  render() {
    var description = [];
    var description_elements = this.props.info.description;
    var description_elements_count = description_elements.length;

    function buildRoleDetails(role_info){
      var role_info_html = [];
      role_info.map(function(role_info_item, j){
        role_info_html.push(<div className='about_block_info_description_details' key={j}>{role_info_item}</div>)
        return null;
      })
      return role_info_html;
    }

    function buildRole(role){
      var role_html = [];
      role_html.push(<div className='about_block_info_description_main'>{role.title}</div>)
      role_html.push(buildRoleDetails(role.info))
      role_html.push(<div className='about_block_info_description_details'>- Location: {role.location}</div>)
      return role_html;
    }

    description_elements.map(function(description_item, i){
      description.push(buildRole(description_item));
      if (description_elements_count - 1 > i) {
        description.push(<div key={i}><hr/></div>)
      }
      return null;
    })

    return (
      <div className='page_block_wrapper' key={this.key}>
        <a href={'link/' + this.props.info.link} target="_blank" rel="noopener noreferrer">
          <img src={require(`${this.props.info.logo}`)} alt={this.props.info.name} className='about_block_image'/>
        </a>
        <div className='about_block_info'>
          <div className='about_block_info_head'>
            <div className='about_block_info_head_institution'>
              <a href={'link/' + this.props.info.link} target="_blank" rel="noopener noreferrer">{this.props.info.name}</a>
            </div>
            <div className='about_block_info_head_dates'>{this.props.info.dates}</div>
          </div>
          <div className='about_block_info_description'>
            {description}
          </div>
        </div>
      </div>
  )}
}

class AboutBlocks extends React.Component {
  render() {
    var aboutRows = []
    this.props.data.map(function(dataRow, i){
      return (
        aboutRows.push(<AboutBlock info={dataRow} key={i} />)
      )
    })
  return (
    <div className='page_section'>
      {aboutRows}
    </div>
  )
  }
}

class About extends React.Component {
  render() {
    return (
      <div className='page_body'>
        <div className='page_subHeading'>Work</div>
        <AboutBlocks data={siteConfigs.work_data} />
        <div className='page_subHeading'>Education</div>
        <AboutBlocks data={siteConfigs.school_data} />
      </div>
      )}
}

export default About