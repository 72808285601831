import React from 'react';
import './style/App.css';

import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import Home from './Home';
import About from './About';
import Contact from './Contact';
import Header from './Header';
import Footer from './Footer';
import Site from './Site';
import Navigation from './Navigation';
import StatusBar from './Status';
import RPLink from './RPLink';
import siteConfigs from './configs/config.json';


ReactGA.initialize(siteConfigs.analytics);

const history = createBrowserHistory();

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const App = () => (
  <Router history={history} >
    <div>
      <Header />
      <div className='body'>
        <Navigation />
        <StatusBar />
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/site" component={Site} />
        <Route path="/contact" component={Contact} />
        <Route path="/link/:id" component={RPLink} />
      </div>
      <Footer />
    </div>
  </Router>
)
export default App
