import React from 'react';
import './style/App.css';
import './style/Header.css';
import siteConfigs from './configs/config.json';

class Header extends React.Component {
  render() {
    const img_loc = siteConfigs.root.logo;
    const img_header = siteConfigs.root.header;
    var bg=require(`${img_header}`);
    let headerStyle = {
      backgroundImage: "url("+bg+")",
      backgroundColor: "#000",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }
    return (
      <div className='header' style={ headerStyle }>
        <a href={siteConfigs.root.url} rel="noopener noreferrer">
          <img src={require(`${img_loc}`)} alt={siteConfigs.root.url} className='logo'/>
        </a>
      </div>
      )}
}

export default Header