import React from 'react';
import './style/App.css';
import siteConfigs from './configs/config.json';

class Bar extends React.Component {
  render(){
  return (
    <div className='bar' style={this.props.barData.bar_style}>
      <div className='text' style={this.props.barData.text_style}>
        {this.props.barData.text}
      </div>
    </div>
  )
  }
}

class Bars extends React.Component {
  render(){
    var statusBars = []
    this.props.data.map(function(data, i){
      if (data.enabled){
        return (
          statusBars.push(<Bar barData={data} key={i} />)
        )
      } else {
        return(null)
      }
    })
  return (
    <div className='bars'>
      {statusBars}
    </div>
  )
  }
}

class Status extends React.Component {
  render() {
    return (
      <div className='status'>
        <Bars data={siteConfigs.status} />
      </div>
  )}
}

export default Status